<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/education" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">{{ title }}</h3>
        <div class="card-options">
          <router-link
            :to="'/profile/education/update/' + $route.params.id + '/planner'"
            class="btn btn-primary"
            v-if="type == 2"
            >Eğitim Planı</router-link
          >
          <router-link
            :to="'/profile/education/update/' + $route.params.id + '/moduller'"
            class="btn btn-primary"
            v-if="type == 3"
            >Eğitim Modülleri</router-link
          >
        </div>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <ul style="color: red; list-style: auto">
              <li
                style="list-style: auto"
                v-for="(item, i) in decline_list"
                :key="i"
              >
                {{ item.content }}
              </li>
            </ul>
            <div class="form-group">
              <label class="form-label">Eğitim Başlık</label>
              <input
                type="text"
                class="form-control"
                name="title"
                v-model="title"
                placeholder="Başlık"
              />
            </div>

            <div class="form-group">
              <label class="form-label">Eğitim Kategorisi</label>
              <select class="form-control" v-model="category" name="category">
                <option disabled selected>Seçiniz</option>
                <option
                  v-for="(service, i) in services"
                  :key="i"
                  :value="service.id"
                >
                  {{ service.experience_name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label class="form-label">Açıklama</label>
              <div id="content" v-html="description"></div>
            </div>

            <div class="form-group">
              <label class="form-label">Süre (Saat)</label>
              <template v-if="status == 1">
                <div class="form-control">{{ duration }}</div>
              </template>
              <template v-else>
                <input
                  type="text"
                  class="form-control"
                  v-model="duration"
                  name="duration"
                  placeholder="Süre"
                />
              </template>
            </div>
            <div class="form-group">
              <label class="form-label">Katılımcı Sayısı</label>
              <template v-if="status == 1">
                <div class="form-control">{{ participant_size }}</div>
              </template>
              <template v-else>
                <input
                  type="text"
                  class="form-control"
                  name="participant_size"
                  v-model="participant_size"
                  placeholder="Katılımcı Sayısı"
                />
              </template>
            </div>
            <div class="form-group">
              <label class="form-label">Kısa Açıklama</label>
              <input
                type="text"
                class="form-control"
                name="short_descriptiption"
                v-model="short_description"
                placeholder="Kısa Açıklama"
              />
            </div>
            <div class="form-group">
              <TagListComponent :tags="tags" @tagList="tagL($event)" />
            </div>
          </div>
          <div class="col-6" v-if="type != 3">
            <div class="form-group">
              <label class="form-label">Başlama Tarihi</label>
              <template v-if="status == 1">
                <div class="form-control">{{ beginDate }}</div>
              </template>
              <template v-else>
                <input
                  type="date"
                  class="form-control"
                  v-model="beginDate"
                  name="title"
                />
              </template>
            </div>
          </div>
          <div class="col-6" v-if="type != 3">
            <div class="form-group">
              <label class="form-label">Bitiş Tarihi</label>
              <template v-if="status == 1">
                <div class="form-control">{{ endDate }}</div>
              </template>
              <template v-else>
                <input
                  type="date"
                  v-model="endDate"
                  :min="beginDate"
                  class="form-control"
                  name="title"
                />
              </template>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="form-group">
              <label class="form-label">Kapak Resmi</label>
              <button
                v-if="image1 != null"
                class="btn btn-primary float-right m-2"
                @click="fileRemove('image1')"
              >
                Sil
              </button>
              <img
                v-if="image1 != null"
                :src="'https://storage.terapivitrini.com/' + image1"
              />
              <croppa
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                v-model="img1"
                v-if="image1 == null"
                :width="520"
                :height="320"
                :placeholder="'Resim Seçin 1040x640'"
                :placeholder-font-size="15"
                :accept="'image/*'"
                :file-size-limit="0"
                :quality="2"
                :zoom-speed="1"
                :disabled="false"
                :disable-drag-and-drop="false"
                :disable-click-to-choose="false"
                :disable-drag-to-move="false"
                :disable-scroll-to-zoom="false"
                :disable-rotation="false"
                :prevent-white-space="false"
                :reverse-scroll-to-zoom="false"
                :show-remove-button="true"
                :remove-button-color="'#c58aa6'"
                :remove-button-size="20"
                canvas-color="transparent"
              ></croppa>
              <button
                v-if="image1 == null"
                class="btn btn-primary m-2"
                @click="fileUpload(img1, 'image1')"
              >
                Yükle
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <button
              v-if="image2 != null"
              class="btn btn-primary float-right m-2"
              @click="fileRemove('image2')"
            >
              Sil
            </button>
            <img
              v-if="image2 != null"
              :src="'https://storage.terapivitrini.com/' + image2"
            />
            <croppa
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-if="image2 == null"
              v-model="img2"
              :width="260"
              :height="160"
              :placeholder="'Resim Seçin 1040x640'"
              :placeholder-font-size="15"
              :accept="'image/*'"
              :file-size-limit="0"
              :quality="4"
              :zoom-speed="1"
              :disabled="false"
              :disable-drag-and-drop="false"
              :disable-click-to-choose="false"
              :disable-drag-to-move="false"
              :disable-scroll-to-zoom="false"
              :disable-rotation="false"
              :prevent-white-space="false"
              :reverse-scroll-to-zoom="false"
              :show-remove-button="true"
              :remove-button-color="'#c58aa6'"
              :remove-button-size="20"
              canvas-color="transparent"
            ></croppa>
            <button
              v-if="image2 == null"
              class="btn btn-primary m-2"
              @click="fileUpload(img2, 'image2')"
            >
              Yükle
            </button>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <button
              v-if="image3 != null"
              class="btn btn-primary float-right m-2"
              @click="fileRemove('image3')"
            >
              Sil
            </button>
            <img
              v-if="image3 != null"
              :src="'https://storage.terapivitrini.com/' + image3"
            />
            <croppa
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-if="image3 == null"
              v-model="img3"
              :width="260"
              :height="160"
              :placeholder="'Resim Seçin 1040x640'"
              :placeholder-font-size="15"
              :accept="'image/*'"
              :file-size-limit="0"
              :quality="4"
              :zoom-speed="1"
              :disabled="false"
              :disable-drag-and-drop="false"
              :disable-click-to-choose="false"
              :disable-drag-to-move="false"
              :disable-scroll-to-zoom="false"
              :disable-rotation="false"
              :prevent-white-space="false"
              :reverse-scroll-to-zoom="false"
              :show-remove-button="true"
              :remove-button-color="'#c58aa6'"
              :remove-button-size="20"
              canvas-color="transparent"
            ></croppa>
            <button
              v-if="image3 == null"
              class="btn btn-primary m-2"
              @click="fileUpload(img3, 'image3')"
            >
              Yükle
            </button>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
            <button
              v-if="image4 != null"
              class="btn btn-primary float-right m-2"
              @click="fileRemove('image4')"
            >
              Sil
            </button>
            <img
              v-if="image4 != null"
              :src="'https://storage.terapivitrini.com/' + image4"
            />
            <croppa
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-if="image4 == null"
              v-model="img4"
              :width="260"
              :height="160"
              :placeholder="'Resim Seçin 1040x640'"
              :placeholder-font-size="15"
              :accept="'image/*'"
              :file-size-limit="0"
              :quality="4"
              :zoom-speed="1"
              :disabled="false"
              :disable-drag-and-drop="false"
              :disable-click-to-choose="false"
              :disable-drag-to-move="false"
              :disable-scroll-to-zoom="false"
              :disable-rotation="false"
              :prevent-white-space="false"
              :reverse-scroll-to-zoom="false"
              :show-remove-button="true"
              :remove-button-color="'#c58aa6'"
              :remove-button-size="20"
              canvas-color="transparent"
            ></croppa>
            <button
              v-if="image4 == null"
              class="btn btn-primary m-2"
              @click="fileUpload(img4, 'image4')"
            >
              Yükle
            </button>
          </div>
          <div class="col-12 mt-3">
            <div class="form-group">
              <label class="form-label">Eğitim Şekli</label>
              <select class="form-control" v-model="type" name="title">
                <option disabled selected>Seçiniz</option>
                <option value="1">Yüz Yüze</option>
                <option value="2">Online</option>
                <option value="3">Video</option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Ücret</label>
              <template v-if="status == 1">
                <div class="form-control">{{ price }}</div>
              </template>
              <template v-else>
                <input
                  type="text"
                  class="form-control"
                  name="price"
                  v-model="price"
                  placeholder="Ücret"
                />
              </template>
            </div>
            <div class="form-group">
              <label class="form-label">İndirimli Ücret</label>
              <template v-if="status == 1">
                <div class="form-control">{{ dumping_price }}</div>
              </template>
              <template v-else>
                <input
                  type="text"
                  class="form-control"
                  name="dumping_price"
                  v-model="dumping_price"
                  placeholder="İndirimli Ücret"
                />
              </template>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">İl</label>
              <select
                name="city"
                id="city"
                class="form-control"
                @change="cityChange"
              >
                <optgroup label="Şehir Seçiniz">
                  <option
                    v-for="(item, i) in cityList"
                    :key="i"
                    @click="cityChange"
                    :selected="
                      city == item.name ? districts(item.districts) : false
                    "
                    :value="item.name"
                    :data-province="JSON.stringify(item.districts)"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">İlçe</label>
              <select name="province" id="province" class="form-control">
                <optgroup label="İlçe Seçiniz">
                  <option
                    v-for="(item, i) in selectedDistricts"
                    :key="i"
                    :selected="district == item.name ? true : false"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-12">
            <label class="form-switch">
              <input
                type="checkbox"
                name="form-switch-checkbox"
                class="form-switch-input"
                v-model="certificate"
                :disabled="status == 1 ? true : false"
              />
              <span class="form-switch-indicator"></span>
              <span class="form-switch-description">Serifika</span>
            </label>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary float-left"
        >
          Değişiklikleri Kaydet
        </button>
        <button
          type="button"
          @click="approvedRequest($route.params.id)"
          id="saveButton"
          class="btn btn-primary float-right"
        >
          Onaya Gönder
        </button>
      </div>
    </div>
  </div>
</template>
  <script>
import Swal from "sweetalert2";
import ImagaGallery from "../Media/ImagaGallery.vue";
import TagListComponent from "../../../../components/TagListComponent.vue";
export default {
  created() {
    document.title = "Eğitim Güncelle";
    if (
      this.$store.state.user_type != "EXPERT" &&
      this.$store.state.trainer != 1
    ) {
      this.$router.push("/profile");
    }
    let serviceInfo = {};
    this.$store.dispatch("serviceList", serviceInfo).then((value) => {
      this.services = value;
    });
    if (this.cityList.length == 0) {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
      });
    }
    this.$store
      .dispatch("educationUserGet", { id: this.$route.params.id })
      .then((value) => {
        this.data = value[0];
        this.title = value[0].title;
        $("#content").summernote("code", value[0].content);
        this.description = value[0].content;
        this.category = value[0].category;
        this.duration = value[0].duration;
        this.participant_size = value[0].participant_size;
        this.short_description = value[0].short_description;
        this.beginDate = value[0].begin_date;
        this.endDate = value[0].end_date;
        this.type = value[0].education_type;
        this.price = value[0].price;
        this.tags = value[0].tags.toString();
        this.dumping_price = value[0].dumping_price;
        this.certificate = value[0].certificate;

        this.city = value[0].city;
        this.district = value[0].district;
        this.status = value[0].status;
        this.decline_list = value[0].decline_list;
        this.status = value[0].status;
        if (value[0].images.length > 0) {
          this.image1 = value[0].images[0].path;
          this.image2 = value[0].images[1].path;
          this.image3 = value[0].images[2].path;
          this.image4 = value[0].images[3].path;
        }
      });
  },
  data() {
    return {
      status: 0,
      data: [],
      cityList: [],
      status: "",
      selectedDistricts: [],
      title: "",
      category: "",
      services: [],
      description: "",
      duration: "",
      participant_size: "",
      short_description: "",
      tags: [],
      decline_list: [],
      beginDate: "",
      endDate: "",
      img1: null,
      img2: null,
      img3: null,
      img4: null,
      image1: null,
      image2: null,
      image3: null,
      image4: null,
      type: "",
      price: 0,
      city: "",
      district: "",
      dumping_price: 0,
      certificate: 0,
    };
  },
  methods: {
    tagL: function (e) {
      this.tags = e;
    },
    approvedRequest(id) {
      Swal.fire({
        title: "Eğitimi onaya göndermek istediğinize emin misin?",
        text: "Bu işlem sonrası seçilen eğtim onaya gönderilecek. Onay sürecinde eğitimde değişiklik yapamazsınız, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          let info = { id: id };
          this.$store
            .dispatch("educationApprovedRequest", info)
            .then((value) => {
              if (value.message == "SCCxSCC") {
                this.$vToastify.success("Eğitim onaya gönderildi", "Başarılı!");
              }
              if (value.message == "ERRxEARARVD") {
                this.$vToastify.warning(
                  "Eğitim zaten onaya gönderilmiş",
                  "Uyarı!"
                );
              }
            });
        }
      });
    },
    cityChange() {
      var element = document.getElementById("city");
      var option_doj =
        element.options[element.selectedIndex].getAttribute("data-province");
      this.selectedDistricts.splice(0, this.selectedDistricts.length);
      let a = JSON.parse(option_doj);
      a.forEach((element) => {
        this.selectedDistricts.push(element);
      });
    },
    districts(item) {
      this.selectedDistricts = item;
      return true;
    },
    fileRemove(path) {
      this[path] = null;
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    fileUpload(image, path) {
      let base64img = image.generateDataUrl();
      let file = this.dataURLtoFile(base64img, "education.jpg");
      let formData = new FormData();
      formData.append("file", file);

      this.$store
        .dispatch("educationMediaUpload", formData)
        .then((response) => {
          this.$vToastify.success(
            "Resim başarılı bir şekilde yüklendi",
            "Başarılı!"
          );
          if (path == "image1") {
            this.image1 = response.education_image_url;
          }
          if (path == "image2") {
            this.image2 = response.education_image_url;
          }
          if (path == "image3") {
            this.image3 = response.education_image_url;
          }
          if (path == "image4") {
            this.image4 = response.education_image_url;
          }
        });
    },
    tagAdd(event) {
      if (event.key === "Enter") {
        this.tags.push(event.target.value);
      }
    },
    save() {
      if (this.status == 1) {
        Swal.fire({
          title: "Onaylı eğitimi güncellemek istediğine emin misin?",
          text: "Bu işlem sonrası yapılan değişiklikler için eğtim onaya gönderilecek. Onay sürecinde eğitimde değişiklik yapamazsınız, devam etmek istiyor musun?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Devam",
          cancelButtonText: "Vazgeç",
        }).then((result) => {
          if (result.isConfirmed) {
            this.process();
          }
        });
      } else {
        this.process();
      }
    },
    process() {
      this.city = document.querySelector("select[name=city]").value;
      this.district = document.querySelector("select[name=province]").value;
      this.description = $("#content").summernote("code");
      if (this.title == null || this.title == "") {
        this.$vToastify.warning("Eğitim başlığı boş olamaz", "Uyarı!");
      } else if (this.category == null || this.category == "") {
        this.$vToastify.warning("Kategori seçilmedi!", "Uyarı!");
      } else if (this.description == null || this.description == "") {
        this.$vToastify.warning("Açıklama girilmedi", "Uyarı!");
      } else if (this.duration == null || this.duration == "") {
        this.$vToastify.warning("Süre girilmedi", "Uyarı!");
      } else if (this.participant_size == null || this.participant_size == "") {
        this.$vToastify.warning("Katılımcı sayısı girilmedi", "Uyarı!");
      } else if (
        this.short_description == null ||
        this.short_description == ""
      ) {
        this.$vToastify.warning("Kısa açıklama girilmedi", "Uyarı!");
      } else if (
        (this.beginDate == null || this.beginDate == "") &&
        this.type != 3
      ) {
        this.$vToastify.warning("Başlangıç tarihi girilmedi", "Uyarı!");
      } else if (
        (this.endDate == null || this.endDate == "") &&
        this.type != 3
      ) {
        this.$vToastify.warning("Bitiş tarihi girilmedi", "Uyarı!");
      } else if (this.type == null || this.type == "") {
        this.$vToastify.warning("Eğitim türü girilmedi", "Uyarı!");
      } else if (this.price == null || this.price == "") {
        this.$vToastify.warning("Fiyat bilgisi girilmedi", "Uyarı!");
      } else if (this.city == null || this.city == "") {
        this.$vToastify.warning("Şehir bilgisi girilmedi", "Uyarı!");
      } else if (this.district == null || this.district == "") {
        this.$vToastify.warning("İlçe bilgisi girilmedi", "Uyarı!");
      } else if (this.dumping_price > 0 && this.dumping_price > this.price) {
        this.$vToastify.warning(
          "İndirimli fiyat normal fiyattan büyük olamaz",
          "Uyarı!"
        );
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let educationUpdate = {
          id: this.$route.params.id,
          title: this.title,
          category: this.category,
          description: this.description,
          duration: this.duration,
          participant_size: this.participant_size,
          short_description: this.short_description,
          beginDate: this.beginDate,
          endDate: this.endDate,
          img1: this.image1,
          img2: this.image2,
          img3: this.image3,
          img4: this.image4,
          type: this.type,
          price: this.price,
          city: this.city,
          tags: this.tags,
          district: this.district,
          dumping_price: this.dumping_price,
          certificate: this.certificate,
        };
        this.$store
          .dispatch("educationUpdate", educationUpdate)
          .then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxUAR") {
                this.$vToastify.warning(
                  "Onay sürecindeki profil güncellenemez",
                  "Uyarı!"
                );
              }
              if (value.message == "ERRxTNACPT") {
                this.$vToastify.warning(
                  "Tarih aralığı ve eğitim süresi kabul edilen sınırın dışında",
                  "Uyarı!"
                );
              }
              if (value.message == "ERRxUNACCPT") {
                this.$vToastify.warning(
                  "Tarih aralıkları bugün ve bugünden küçük olamaz",
                  "Uyarı!"
                );
              }
            } else {
              if (this.status == 1) {
                this.$vToastify.success(
                  "Eğitim güncellendi ve onaya gönderildi",
                  "Başarılı!"
                );
                this.$router.push("/profile/education");
              }
              this.$vToastify.success(
                "Eğitim başarılı bir şekilde güncellendi",
                "Başarılı!"
              );
            }
            document.getElementById("saveButton").disabled = false;
            document.getElementById("saveButton").innerHTML =
              "Değişiklikleri Kaydet";
          });
      }
    },
  },
  components: { ImagaGallery, TagListComponent },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://terapivitrini.com/assets/plugins/summer-note/summernote1.js"
    );
    document.head.appendChild(recaptchaScript);
    $(document).ready(function () {
      $("#content").summernote({
        toolbar: [
          [
            "style",
            ["highlight", "bold", "italic", "underline", "clear", "style"],
          ],
          ["font", ["strikethrough", "superscript", "subscript", "fontname"]],
          ["color", ["color"]],
          ["table", ["table"]],
          ["para", ["ul", "ol", "paragraph", "hr"]],
          ["insert", ["tableofcontent"]],
          ["link", ["linkDialogShow", "unlink"]],
          ["view", ["fullscreen", "codeview", "help"]],
        ],
      });
    });
  },
};
</script>