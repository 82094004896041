<template>
    <div class="form-row">
      <div class="form-group col-md-12">
        <div class="form-group">
          <label class="form-label" for="tags">Etiket Ekle</label>
          <input
            list="datalist"
            @keypress="add"
            class="form-control"
          />
          <datalist id="datalist">
            <option v-for="(item, i) in tagList" :key="i" :value="item">
              {{ item }}
            </option>
          </datalist>
          <label class="form-label" for="tags">Etiket</label>
          <span
            v-for="(item, i) in list"
            class="badge bg-primary m-1"
            :key="'tag-' + i"
            >{{ item }} <i @click="remove(i)" class="fa fa-close"></i
          ></span>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: ["tags"],
    mounted() {
      setTimeout(() => {
        this.list = this.tags.length > 0 ? this.tags.split(",") : [];
      }, 2000);
    },
    data() {
      return {
        tagList: [],
        list: this.tags.length > 0 ? this.tags.split(",") : [],
      };
    },
    methods: {
      add(e) {
        if (e.key === "Enter") {
          if (e.value != "") {
            e.target.value.split(",").forEach((a) => {
              this.list.push(a);
            });
  
            this.$emit("tagList", String(this.list));
            e.target.value = "";
          }
        }
      },
      remove(index) {
        this.list.splice(index, 1);
        this.$emit("tagList", String(this.list));
      },
    },
  };
  </script>